/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Yesteryear';
  src: url('/assets/fonts/Yesteryear-Regular.woff2') format('woff2'),
       url('/assets/fonts/Yesteryear-Regular.woff') format('woff'),
       url('/assets/fonts/Yesteryear-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kidwriting Pro';
  src: url('/assets/fonts/KidwritingProBold.woff2') format('woff2'),
       url('/assets/fonts/KidwritingProBold.woff') format('woff'),
       url('/assets/fonts/KidwritingProBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kidwriting Pro';
  src: url('/assets/fonts/KidwritingProRegular.woff2') format('woff2'),
       url('/assets/fonts/KidwritingProRegular.woff') format('woff'),
       url('/assets/fonts/KidwritingProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.font-kidwriting {
  font-family: 'Kidwriting Pro', Comic Sans MS, Comic Sans, sans-serif;
}

.fancybox__content {
  background: transparent !important;
  max-width: 1152px !important;
  margin: 0 auto !important;
}

.fancybox__backdrop {
  background: rgba(0, 0, 0, 0.65) !important;
}

.fancybox__content > .f-button.is-close-btn {
  width: 50px !important;
  height: 50px !important;
  background-color: #0F0F12;
}

.fancybox__content > .f-button.is-close-btn:hover {
  background-color: #363639;
}

.bubble {
  margin-top: -15px;
  text-align: center;
  color: black;
  max-width: 90%;
  margin: 0 auto;
  font-size: 24px;
  line-height: 1.2;
  z-index: 3;
  position: absolute;
  left: 40%;
  top: 15%;
  width: 50%;
  background: rgba(255, 255, 255, 0.75);
  border-radius: 999px;
  padding: 0.5rem 1.25rem;
}

.bubble img {
  padding: 0.5rem 4rem;
}

.bubble a {
  color: #15407E;
  text-decoration: underline;
}

.bubble a:hover {
  color: #146FF0;
}


@media (max-width: 700px) {
  .bubble {
    border-radius: 20px;
    font-size: 16px;
    line-height: 1;
    padding: 0.75rem 1rem;
  }
  .bubble img {
    padding: 0.2rem 0.4rem;
  }
}
